<template>
  <div>
    <b-row
      v-if="order"
      class="d-flex justify-content-center align-items-center"
    >
      <b-col xl="5" lg="5" md="12" sm="12" xs="12">
        <b-card
          no-body
          class="mb-0 p-2 text-center d-flex flex-column justify-content-center align-items-center h-100"
        >
          <h4>{{ order.sender_address ? order.sender_address.name : '' }}</h4>
          <h6>{{ order.sender_address ? order.sender_address.phone : '' }}</h6>
          <h6>
            {{ order.sender_address ? order.sender_address.complete : '' }}
          </h6>
        </b-card>
      </b-col>
      <b-col
        xl="2"
        lg="2"
        md="12"
        sm="12"
        xs="12"
        class="text-center my-2 text-primary"
      >
        <feather-icon width="30" height="30" icon="ChevronsRightIcon" />
        <feather-icon width="30" height="30" icon="ChevronsRightIcon" />
        <feather-icon class="mx-2" width="50" height="50" icon="TruckIcon" />
        <feather-icon width="30" height="30" icon="ChevronsRightIcon" />
        <feather-icon width="30" height="30" icon="ChevronsRightIcon" />
      </b-col>
      <b-col xl="5" lg="5" md="12" sm="12" xs="12">
        <b-card
          no-body
          class="mb-0 p-2 text-center d-flex flex-column justify-content-center align-items-center h-100"
        >
          <h4>{{ order.outlet_address ? order.outlet_address.name : '' }}</h4>
          <h6>{{ order.outlet_address ? order.outlet_address.phone : '' }}</h6>
          <h6>
            {{ order.outlet_address ? order.outlet_address.complete : '' }}
          </h6>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        xs="12"
        class="mb-sm-2 mb-xs-5 mb-md-2 mb-lg-0 mb-xl-0"
      >
        <b-card
          no-body
          class="mb-0 p-2 text-center d-flex flex-column justify-content-center align-items-center h-100"
        >
          <h4>{{ $t('delivery_date') }}</h4>
          <h6>
            {{
              order.delivery_date
                ? new Intl.DateTimeFormat('en-MY', {
                    dateStyle: 'full'
                  }).format(new Date(order.delivery_date))
                : ''
            }}
          </h6>
        </b-card>
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12" xs="12">
        <b-card
          no-body
          class="mb-0 p-2 text-center d-flex flex-column justify-content-center align-items-center h-100"
        >
          <h4>{{ $t('delivery_time') }}</h4>
          <h6>
            {{
              order.delivery_date
                ? new Intl.DateTimeFormat('en-MY', {
                    hourCycle: 'h12',
                    timeStyle: 'short'
                  }).format(new Date(order.delivery_date))
                : ''
            }}
          </h6>
          <span class="text-muted">{{ $t('delivery_date_info') }}</span>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-table responsive="sm" :items="items">
            <template #cell(quantity)="">
              <ValidationObserver ref="maxQuantityCheck" v-slot="{ passes }">
                <form @submit.prevent="passes(fetchCharges)">
                  <ValidationProvider
                    #default="{ errors }"
                    vid="quantity"
                    name="Product Quantity"
                  >
                    <b-form-input
                      id="quantity"
                      v-model="order.qty"
                      type="number"
                      min="1"
                      max="1000"
                      :disabled="!hasPriceSet"
                      :placeholder="$t('quantity')"
                      @input="fetchCharges"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </form>
              </ValidationObserver>
            </template>
            <template #cell(unit_price)="">
              <span v-if="!isFetchingPrice">{{
                $helpers.formatTotal(charges.unitPrice)
              }}</span>
              <b-spinner
                v-else
                variant="primary"
                :label="$t('src.layouts.delivery-summary.timeline.dowloading')"
              />
            </template>
            <template #cell(amount)="">
              <span v-if="!isFetchingPrice">{{
                $helpers.formatTotal(charges.b2b)
              }}</span>
              <b-spinner
                v-else
                variant="primary"
                :label="$t('src.layouts.delivery-summary.timeline.dowloading')"
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col md="12">
        <b-card no-body class="mb-0 p-2 d-flex justify-content-center">
          <b-row>
            <b-col
              md="8"
              class="d-flex align-items-center mb-2 mb-xs-5 mb-lg-0 mb-xl-0"
            >
              <strong>{{ $t('we_accept') }}</strong>
              <img
                :src="require('@/assets/images/payments/visa.png')"
                style="padding: 0px 10px"
              />
              <img :src="require('@/assets/images/payments/mastercard.png')" />
            </b-col>
            <b-col md="4">
              <div class="d-flex flex-column">
                <b-row class="mb-1">
                  <b-col cols="12">
                    <b-form-group :label="$t('voucher_code')">
                      <v-select
                        v-model="order.selectedVoucher"
                        label="code_name"
                        :options="vouchers"
                        :disabled="!hasPriceSet"
                        :reduce="(voucher) => voucher.uuid"
                        @input="fetchCharges"
                      >
                        <span slot="no-options">
                          {{ $t('no_available_voucher') }}
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col md="12" class="d-flex justify-content-between">
                    <h4 class="mb-0">
                      {{ $t('subtotal') }}
                    </h4>
                    <h4 v-if="!isFetchingPrice" class="mb-0">
                      {{ $helpers.formatTotal(charges.subTotal) }}
                    </h4>
                    <b-spinner
                      v-else
                      variant="primary"
                      :label="$t('downloading')"
                    />
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col md="12" class="d-flex justify-content-between">
                    <h4 class="mb-0">
                      {{ $t('total_discounts') }}
                    </h4>
                    <h4 v-if="!isFetchingPrice" class="mb-0">
                      {{ $helpers.formatTotal(charges.voucherDiscounts) }}
                    </h4>
                    <b-spinner
                      v-else
                      variant="primary"
                      :label="$t('downloading')"
                    />
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col md="12" class="d-flex justify-content-between">
                    <h4 class="mb-0">
                      {{ $t('pickup_charge') }}
                    </h4>
                    <h4 v-if="!isFetchingPrice" class="mb-0">
                      {{ $helpers.formatTotal(charges.pickUpCharge) }}
                    </h4>
                    <b-spinner
                      v-else
                      variant="primary"
                      :label="$t('downloading')"
                    />
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col md="12" class="d-flex justify-content-between">
                    <h4 class="mb-0">
                      {{ $t('tax_charge') }}
                    </h4>
                    <h4 v-if="!isFetchingPrice" class="mb-0">
                      {{ charges.taxCharge || 0 }}%
                    </h4>
                    <b-spinner
                      v-else
                      variant="primary"
                      :label="$t('downloading')"
                    />
                  </b-col>
                </b-row>

                <!-- CHOP & SIGN -->
                <b-row class="mb-1">
                  <b-col md="12" class="d-flex justify-content-between">
                    <span class="d-flex">
                      <h4 class="mb-0">
                        {{ $t('chop_sign') }}
                      </h4>
                      <feather-icon
                        id="chopsign"
                        size="15"
                        icon="InfoIcon"
                        style="
                          margin-bottom: 5px;
                          color: #93b5f1;
                          margin-left: 3px;
                        "
                      />
                      <b-tooltip target="chopsign" triggers="hover" no-fade>
                        These fees are optional. You can select below if you'd
                        like to request the 'Chop & Sign' service for the DO.
                      </b-tooltip>
                    </span>
                    <h4 v-if="!isFetchingPrice" class="mb-0">
                      {{ $helpers.formatTotal(charges.chop_sign) }}
                    </h4>
                    <b-spinner
                      v-else
                      variant="primary"
                      :label="$t('downloading')"
                    />
                  </b-col>
                  <b-col class="ml-1">
                    <b-form-group v-slot="{ ariaDescribedby }" label="">
                      <b-form-radio
                        v-model="charges.chop_sign"
                        :aria-describedby="ariaDescribedby"
                        name="chop_sign"
                        :value="5"
                        @change="handleChopSign"
                      >
                        Yes
                      </b-form-radio>
                      <b-form-radio
                        v-model="charges.chop_sign"
                        :aria-describedby="ariaDescribedby"
                        name="chop_sign"
                        :value="0"
                        @change="handleChopSign"
                      >
                        No
                      </b-form-radio>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="12" class="d-flex justify-content-between">
                    <h4 class="mb-0 text-primary py-2">
                      {{ $t('grand_total') }}
                    </h4>
                    <h4 v-if="!isFetchingPrice" class="mb-0 py-2">
                      {{ $helpers.formatTotal(charges.totalCharges) }}
                    </h4>
                    <b-spinner
                      v-else
                      variant="primary"
                      :label="$t('downloading')"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'CheckoutOrder',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    order: {
      type: [Object, Array],
      default: () => {}
    },
    items: {
      type: Array,
      default: () => {}
    },
    charges: {
      type: Object,
      default: () => {}
    },
    isFetchingPrice: {
      type: Boolean,
      default: false
    },
    hasPriceSet: {
      type: Boolean,
      default: false
    },
    vouchers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      hasPrevChop: false
    }
  },
  methods: {
    async fetchCharges() {
      const response = await this.$store.dispatch('singleDelivery/fetchCharges')
      if (response.status === 422) {
        this.$refs.maxQuantityCheck.setErrors(response.data.errors)
        return false
      }
      return true
    },
    handleChopSign() {
      if (this.charges.chop_sign) {
        this.charges.totalCharges = this.charges.totalCharges + 5
        this.hasPrevChop = true
      } else {
        if (this.hasPrevChop) {
          this.charges.totalCharges = this.charges.totalCharges - 5
        }
        this.hasPrevChop = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
