<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        :label="$t('pickup_address')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          vid="pickup_id"
          name="Pick Up Address"
        >
          <v-select
            id="pickup-point"
            v-model="form.sender_address"
            :options="pickUpAddresses"
            :label="'name'"
            :selectable="(option) => option.type=='child'"
            :clearable="false"
            @input="(option) => $emit('setPickupId', {pickup_id: option.id})"
          >
            <template #option="{name, type, complete }">
              <span v-if="type == 'child'" />
              <span>{{ name }}<br>
                <small>{{ complete }}</small>
              </span>
            </template>
          </v-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </validationprovider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        :label="$t('delivery_date')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          vid="delivery_date"
          name="Delivery Date"
        >
          <flat-pickr
            id="delivery-date"
            v-model="form.delivery_date"
            :config="deliveryDateTimePickerConfig"
            :placeholder="$t('src.views.pages.inside.createdelivery.yyyymmddH')"
            class="form-control"
            @on-open.once="handleDateAlert"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validationprovider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'PickUpAddressAndDeliveryDate',
  components: {
    vSelect,
    flatPickr,
    ValidationProvider,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    pickUpAddresses: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    deliveryDateTimePickerConfig() {
      const today = new Date()
      today.setDate(today.getDate() + 1)
      today.setHours(12, 0)
      return {
        time_24hr: false,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        minDate: today,
        defaultHour: 12,
        defaultMinute: 0,
      }
    },
  },
  methods: {
    handleDateAlert() {
      this.$swal({
        title: this.$t('delivery_date'),
        text: this.$t('delivery_date_info'),
        icon: 'warning',
        confirmButtonText: this.$t('ok'),
        confirmButtonColor: '#ea5455',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
