<template>
  <b-row>
    <b-col md="4">
      <b-form-group
        :label="$t('select_type_of_market')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.type_of_market` : 'type_of_market'}`"
          name="Type Of Market"
        >
          <v-select
            v-model="form.type_of_market"
            :options="markets"
            :clearable="false"
            label="name"
            @input="$emit('setRecipients')"
          />
          <span class="text-danger">{{ errors[0] }}</span>
        </validationprovider>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group
        :label="$t('select_recipient')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.client_id` : 'recipient'}`"
          name="Recipient"
        >
          <v-select
            v-model="form.recipient"
            :options="recipients"
            :clearable="false"
            :label="'name'"
            @input="$emit('setOutlets')"
          >
            <template #option="{name, logo }">
              <div class="d-flex flex-row align-items-center">
                <b-img-lazy
                  v-bind="mainProps"
                  :src="logo"
                  :alt="name"
                  rounded="circle"
                />
                <span class="m-0">
                  {{ name }}
                </span>
              </div>
            </template>
          </v-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </validationprovider>
      </b-form-group>
    </b-col>
    <b-col md="4">
      <b-form-group
        :label="$t('select_outlet')"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :vid="`${typeOfOrder === 'bulk order' ? `orders.${index}.dropoff_id` : 'dropoff_id'}`"
          name="Outlet"
        >
          <v-select
            v-model="form.outlet_address"
            :label="'name'"
            :options="outlets"
            :selectable="(option) => option.type=='child'"
            :clearable="false"
            @input="typeOfOrder === 'bulk order' ? $emit('calculatePrice') : () =>{}"
          >
            <template #option="{name, type }">
              <span v-if="type == 'child'" />
              <span>{{ name }}</span>
            </template>
          </v-select>
          <span class="text-danger">{{ errors[0] }}</span>
        </validationprovider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  name: 'TypeOfMarketAndClient',
  components: {
    vSelect,
    ValidationProvider,
  },
  props: {
    form: {
      type: [Object, Array],
      default: () => {},
    },
    markets: {
      type: [Object, Array],
      default: () => {},
    },
    recipients: {
      type: [Object, Array],
      default: () => {},
    },
    outlets: {
      type: [Object, Array],
      default: () => {},
    },
    typeOfOrder: {
      type: String,
      default: 'single order',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mainProps: {
        blank: true, blankColor: '#777', width: 75, height: 75, class: 'mr-2',
      },
    }
  },
}
</script>
