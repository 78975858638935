var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pickup_address')}},[_c('ValidationProvider',{attrs:{"vid":"pickup_id","name":"Pick Up Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"pickup-point","options":_vm.pickUpAddresses,"label":'name',"selectable":function (option) { return option.type=='child'; },"clearable":false},on:{"input":function (option) { return _vm.$emit('setPickupId', {pickup_id: option.id}); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var type = ref.type;
var complete = ref.complete;
return [(type == 'child')?_c('span'):_vm._e(),_c('span',[_vm._v(_vm._s(name)),_c('br'),_c('small',[_vm._v(_vm._s(complete))])])]}}],null,true),model:{value:(_vm.form.sender_address),callback:function ($$v) {_vm.$set(_vm.form, "sender_address", $$v)},expression:"form.sender_address"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('delivery_date')}},[_c('ValidationProvider',{attrs:{"vid":"delivery_date","name":"Delivery Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"delivery-date","config":_vm.deliveryDateTimePickerConfig,"placeholder":_vm.$t('src.views.pages.inside.createdelivery.yyyymmddH')},on:{"~on-open":function($event){return _vm.handleDateAlert.apply(null, arguments)}},model:{value:(_vm.form.delivery_date),callback:function ($$v) {_vm.$set(_vm.form, "delivery_date", $$v)},expression:"form.delivery_date"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }