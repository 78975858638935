<template>
  <div class="custom-options-checkable d-flex w-100">
    <input
      :id="id"
      v-model="form.is_pickup"
      class="custom-option-item-check"
      type="radio"
      name="customOptionsCheckableRadios"
      :value="value"
      @input="(event) => $emit('setPickUpAddresses', event.target.value)"
    >
    <label
      class="custom-option-item p-1 d-flex justify-content-center"
      variant="primary"
      :for="id"
    >
      <div class="text-center d-flex flex-column align-items-center justify-content-center">
        <h2 class="text-white text-uppercase">{{ title }}</h2>
        <p class="text-white">{{ description }}</p>
      </div>
    </label>
  </div>
</template>

<script>

export default {
  name: 'DeliveryType',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [Number],
      required: true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
    .custom-options-checkable .custom-option-item {
    width: 100%;
    cursor: pointer;
    border-radius: 0.42rem;
    color: #82868b;
    background-color: rgba(130, 134, 139, 1);
    border: 1px solid #ebe9f1; }

    .custom-options-checkable .custom-option-item .custom-option-item-title {
    color: #82868b; }

    .custom-option-item-check {
    position: absolute;
    clip: rect(0, 0, 0, 0); }

    .custom-option-item-check:checked + .custom-option-item {
      background: linear-gradient(90deg, rgba(255,81,47,1) 0%, rgba(221,36,118,1) 100%) !important;
      box-shadow: 0 8px 25px -8px #ea5455;;
    }

</style>
