var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('select_type_of_market')}},[_c('ValidationProvider',{attrs:{"vid":("" + (_vm.typeOfOrder === 'bulk order' ? ("orders." + _vm.index + ".type_of_market") : 'type_of_market')),"name":"Type Of Market"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.markets,"clearable":false,"label":"name"},on:{"input":function($event){return _vm.$emit('setRecipients')}},model:{value:(_vm.form.type_of_market),callback:function ($$v) {_vm.$set(_vm.form, "type_of_market", $$v)},expression:"form.type_of_market"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('select_recipient')}},[_c('ValidationProvider',{attrs:{"vid":("" + (_vm.typeOfOrder === 'bulk order' ? ("orders." + _vm.index + ".client_id") : 'recipient')),"name":"Recipient"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.recipients,"clearable":false,"label":'name'},on:{"input":function($event){return _vm.$emit('setOutlets')}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var logo = ref.logo;
return [_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('b-img-lazy',_vm._b({attrs:{"src":logo,"alt":name,"rounded":"circle"}},'b-img-lazy',_vm.mainProps,false)),_c('span',{staticClass:"m-0"},[_vm._v(" "+_vm._s(name)+" ")])],1)]}}],null,true),model:{value:(_vm.form.recipient),callback:function ($$v) {_vm.$set(_vm.form, "recipient", $$v)},expression:"form.recipient"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('select_outlet')}},[_c('ValidationProvider',{attrs:{"vid":("" + (_vm.typeOfOrder === 'bulk order' ? ("orders." + _vm.index + ".dropoff_id") : 'dropoff_id')),"name":"Outlet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":'name',"options":_vm.outlets,"selectable":function (option) { return option.type=='child'; },"clearable":false},on:{"input":function($event){_vm.typeOfOrder === 'bulk order' ? _vm.$emit('calculatePrice') : function () {}}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var type = ref.type;
return [(type == 'child')?_c('span'):_vm._e(),_c('span',[_vm._v(_vm._s(name))])]}}],null,true),model:{value:(_vm.form.outlet_address),callback:function ($$v) {_vm.$set(_vm.form, "outlet_address", $$v)},expression:"form.outlet_address"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }