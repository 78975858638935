<template>
  <div>
    <b-row class="mt-2">
      <b-col>
        <b-card>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="items"
            sticky-header
            striped
          >
            <template #cell(order_no)="data">
              <span>{{ data.index + 1 }}</span>
            </template>
            <template #cell(is_pickup)="data">
              <span>{{ data.item.is_pickup ? 'SELF DELIVERY' : 'SMARTTRUCK' }}</span>
            </template>
            <template #cell(sender_address)="data">
              <span>{{ data.item.sender_address ? data.item.sender_address.complete : '' }}</span>
            </template>
            <template #cell(outlet_address)="data">
              <span>{{ data.item.outlet_address ? data.item.outlet_address.complete : '' }}</span>
            </template>
            <template #cell(delivery_date)="data">
              <span>{{ data.item.delivery_date ? $helpers.formatDateTime(data.item.delivery_date) : '' }}</span>
            </template>
            <template #cell(unit_price)="data">
              <span>{{ $helpers.formatTotal(data.item.unit_price) }}</span>
            </template>
            <template #cell(pickup_charge)="data">
              <span>{{ $helpers.formatTotal(data.item.pickup_charge || 0) }}</span>
            </template>
            <template #cell(amount)="data">
              <span>{{ $helpers.formatTotal(data.item.amount || 0) }}</span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col md="12">
        <b-card
          no-body
          class="mb-0 p-2 d-flex justify-content-center"
        >
          <b-row>
            <b-col
              md="8"
              class="d-flex align-items-center mb-2 mb-xs-5 mb-lg-0 mb-xl-0"
            >
              <strong>{{ $t('src.views.pages.inside.deliveryorder.weA') }}</strong>
              <img
                :src="require('@/assets/images/payments/visa.png')"
                style="padding: 0px 10px"
              >
              <img
                :src="require('@/assets/images/payments/mastercard.png')"
              >
            </b-col>
            <b-col md="4">
              <div
                class="d-flex flex-column"
              >
                <b-row class="mb-1">
                  <b-col cols="12">
                    <b-form-group
                      label="Voucher Code"
                    >
                      <v-select
                        v-model="order.selectedVoucher"
                        label="code_name"
                        :options="vouchers"
                        :reduce="voucher => voucher.uuid"
                        @input="$emit('applyVoucherDiscount')"
                      >
                        <span
                          slot="no-options"
                        >
                          No Available Voucher
                        </span>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.subT') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ $helpers.formatTotal(overallCharges.subTotal || 0) }}
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.totalDiscounts') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ $helpers.formatTotal(overallCharges.totalDiscount || 0) }}
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.pickUC') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ $helpers.formatTotal(overallCharges.pickupCharge || 0) }}
                    </h4>
                  </b-col>
                </b-row>

                <b-row class="mb-1">
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0">
                      {{ $t('src.views.pages.inside.deliveryorder.taxC') }}
                    </h4>
                    <h4
                      class="mb-0"
                    >
                      {{ overallCharges.taxCharge }}%
                    </h4>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    md="12"
                    class="d-flex justify-content-between"
                  >
                    <h4 class="mb-0 text-primary py-2">
                      {{ $t('src.views.pages.inside.deliveryorder.grandT') }}
                    </h4>
                    <h4
                      class="mb-0  py-2"
                    >
                      {{ $helpers.formatTotal(overallCharges.grandTotal || 0) }}
                    </h4>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'CheckoutOrder',
  components: {
    vSelect,
  },
  props: {
    order: {
      type: [Object, Array],
      default: () => {},
    },
    items: {
      type: Array,
      default: () => {},
    },
    charges: {
      type: Object,
      default: () => {},
    },
    isFetchingPrice: {
      type: Boolean,
      default: false,
    },
    hasPriceSet: {
      type: Boolean,
      default: false,
    },
    vouchers: {
      type: Array,
      default: () => [],
    },
    overallCharges: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'order_no',
          label: 'Order No.',
          class: 'text-nowrap',
        },
        {
          key: 'is_pickup',
          label: 'Shipment',
          class: 'text-nowrap',
        },
        {
          key: 'sender_address',
          label: 'Pick Up Address',
        },
        {
          key: 'outlet_address',
          label: 'Recipient Address',
        },
        {
          key: 'delivery_date',
          label: 'Delivery Date',
          class: 'text-nowrap',
        },
        {
          key: 'category_name',
          label: 'Product Category',
          class: 'text-nowrap',
        },
        {
          key: 'qty',
          label: 'Quantity',
          class: 'text-nowrap',
        },
        {
          key: 'unit_price',
          label: 'Unit Price',
          class: 'text-nowrap',
        },
        {
          key: 'pickup_charge',
          label: 'Pick Up Charge',
          class: 'text-nowrap',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Price',
          class: 'text-nowrap',
          sortable: true,
        },
      ],
    }
  },
}
</script>
